/**
 * Scripts
 */

require('./components/ajax-post-archive.js')();
require('./components/aos.js')();
require('./components/edge.js')();
require('./components/google-maps.js')();
require('./components/lazy-background.js')();
require('./components/lazy-image.js')();
require('./components/mobile-menu.js')();
require('./components/smooth-scroll.js')();


