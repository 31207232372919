/*------------------------------------*\
    ajax-news
\*------------------------------------*/

module.exports = function() {

    var submenu = {
        loadButtonClass: '.js-load-button',
        loadButtonText: '',
        loadContentClass: '.js-load-content',
        postClass: '.js-post',
        loadMore(response) {
            var loadContent = $(this.loadContentClass);
            var loadButton = $(this.loadButtonClass);
            // Find the content in the response HTML
            var content = $(response).find(this.loadContentClass).html();
            var newButtonUrl = $(response).find(this.loadButtonClass).attr('href');
            var posts = $(response).find(this.postClass);
            loadContent.append(content);
            // If no button is present on the next page, 
            // or if there are fewer than 3 posts,
            // hide the button and don't update the URL
            if(!newButtonUrl || posts.length < 6) {
                loadButton.hide();
            } else {
                loadButton.attr('href', newButtonUrl);
                loadButton.removeClass('is-disabled');
                loadButton.text(this.loadButtonText);
            }
        },
        requestMore(button) {
            var self = this;
            var nextUrl = $(button).attr('href');
            // Get the HTML from the next page
            $.ajax({
                type: 'GET',
                url: nextUrl,
                beforeSend: function() {
                    $(button).addClass('is-disabled');
                    $(button).text('Loading...');
                },
                success: function(response) {
                    self.loadMore(response);
                }
            });
        },
        init() {
            var self = this;
            var loadButton = $(this.loadButtonClass);
            this.loadButtonText = loadButton.text();
            loadButton.click(function(event) {
                event.preventDefault();
                self.requestMore(this);
            });
        }
    }
    submenu.init();
};