module.exports = function(){

    var smoothScroll = {
        linkClass: '.js-smooth-scroll',
        lastDestination: 0,
        focusForm(hash) {
            if(hash.find('input').length) {
                var activeInput = hash.find('input[type=text],textarea,select').filter(':visible:first');
                $(activeInput).focus();
            };
        },
        scrollToDestination(destination) {
            $('html, body').animate({
                scrollTop: destination
            }, 1000, 'swing');
        },
        calculateDestination(hash) {
            var destination = 0,
                newDestination = 0;
            if (hash.offset().top > $(document).height() - $(window).height()) {
                newDestination = $(document).height() - $(window).height();
                destination = newDestination;
            } else {
                newDestination = hash.offset().top;
                destination = newDestination;
            }
            this.lastDestination = newDestination;
            this.scrollToDestination(destination);
            this.focusForm(hash);
        },
        init() {
            var self = this; 
            $(this.linkClass).click(function(event) {
                var hash = $(this.hash);
                event.preventDefault();
                self.calculateDestination(hash);
            });
        }
    };
    
    smoothScroll.init();
};