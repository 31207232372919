module.exports = function(){

    var throttle = require('lodash.throttle');
    var lazyImage = {
        imageClass: '.js-lazy-image',
        handleScroll() {
            var self = this;
            var scrolled = $(window).scrollTop();
            var hiddenElements = $(this.imageClass + '[data-lazy]');
            hiddenElements.each(function(index, hiddenElement) {
                var element = $(hiddenElement);
                var windowHeight = $(window).height();
                var offsetTop = element.offset().top;
                if(scrolled + windowHeight > offsetTop) {
                    var imageSrc = element.attr('data-lazy');
                    element.attr('src', imageSrc).on('load', function() {
                        element.removeAttr('data-lazy');
                    });
                }
            });
        },
        init() {
            var self = this;
            var image = $(this.imageClass);
            if(image.length) {
                // throttle scrolling with lodash for better performance
                window.addEventListener('scroll', throttle(function() {
                    self.handleScroll();
                }, 1000, { trailing: true, leading: true }));
                $(window).on('load resize complete', function() {
                    self.handleScroll();
                });
            }
        }
    };
    
    lazyImage.init();
};