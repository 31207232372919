module.exports = function(){
    
    const bodyScrollLock = require('body-scroll-lock');

    var mobileMenu = {
        menuToggleClass: '.js-mobile-menu-toggle',
        menuClass: '.js-mobile-menu',
        logoClass: '.js-mobile-menu-logo',
        styleChildren() {
            var transitionDelay = 0.2;
            $(this.menuClass + ' li').each(function(index, item) {
                $(item).css('transition-delay', function() {
                    transitionDelay += 0.08;
                    return transitionDelay + 's';
                });
            });
        },
        updateClass() {
            var menu = $(this.menuClass);
            var logo = $(this.logoClass);
            var logoSrc = logo.attr('src');
            $('html').toggleClass('is-mobile-menu-open');
            if($('html').hasClass('is-mobile-menu-open')) {
                if(logo.length) {
                    logo.attr('src', logoSrc.replace('.svg', '-ko.svg'));
                }
                bodyScrollLock.disableBodyScroll(menu);
            } else {
                if(logo.length) {
                    logo.attr('src', logoSrc.replace('-ko.svg', '.svg'));
                }
                bodyScrollLock.clearAllBodyScrollLocks();
            }
        },
        init() {
            var self = this;
            var menuToggle = $(this.menuToggleClass);
            this.styleChildren();
            menuToggle.on('click', function(e) {
                self.updateClass();
                e.preventDefault();
            });
        }
    }

    mobileMenu.init();
};